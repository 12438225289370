var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('ozi-table',{staticClass:"row-pointer",attrs:{"headers":_vm.table.headers,"items":_vm.table.items,"persistent":"serviceListTable"},on:{"click:row":_vm.handleEdit},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.handleCreate.apply(null, arguments)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$tc('label.create'))+" ")],1),_c('v-checkbox',{attrs:{"label":_vm.$t('title.only', [_vm.$tc('service.functional_centre.label', 2)])},model:{value:(
            _vm.$storage['serviceListTable'].customFilters.showFunctionalCentres
          ),callback:function ($$v) {_vm.$set(_vm.$storage['serviceListTable'].customFilters, "showFunctionalCentres", $$v)},expression:"\n            $storage['serviceListTable'].customFilters.showFunctionalCentres\n          "}})],1)]},proxy:true},{key:"item.functional_centre.name.en",fn:function(ref){
          var item = ref.item;
return [(item.functional_centre)?[_vm._v(" "+_vm._s(item.functional_centre.code)+" - "+_vm._s(item.functional_centre.name.en)+" ")]:_vm._e()]}},{key:"item.service_characteristics",fn:function(ref){
          var item = ref.item;
return [[_vm._l((item.service_characteristics),function(characteristic){return _c('v-chip',{key:characteristic.id,staticClass:"mx-1 my-1",attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(_vm.$langobj.parse(characteristic.name_short))+" ")])}),(item.hidden)?_c('v-chip',{staticClass:"mx-1 my-1",attrs:{"color":"orange","label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.$tc('service.hidden_short'))+" ")]):_vm._e()]]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleEdit(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.edit')))])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }