










































































import Vue from 'vue';
import ServiceUtils, { Service } from '@/utils/ServiceUtils';
import { locales } from '@/i18n';

export default Vue.extend({
  computed: {
    table() {
      return {
        items: this.items?.filter((item) => {
          const showFunctionalCentres =
            this.$storage['serviceListTable'].customFilters
              .showFunctionalCentres;
          return showFunctionalCentres ? !!item.functional_centre : true;
        }),
        headers: [
          {
            text: this.$i18n.tc('service.key'),
            value: 'key',
          },
          {
            text: this.$i18n.tc('service.name'),
            value: 'name',
          },
          {
            text: this.$i18n.tc('service.functional_centre.label'),
            value: 'functional_centre.name.en',
          },
          {
            text: this.$i18n.tc('service.characteristics'),
            value: 'service_characteristics',
          },
          {
            text: this.$i18n.tc('service.use_count'),
            value: 'use_count',
            align: 'right',
          },
          {
            text: '',
            value: 'actions',
          },
        ],
      };
    },

    locales() {
      return locales;
    },
  },

  data: (): {
    items?: Service[];
  } => ({
    items: undefined,
  }),

  async mounted() {
    try {
      this.items = await ServiceUtils.service.api.list();
    } catch (error) {
      console.error(error);
    } finally {
      //
    }
  },

  methods: {
    async handleEdit(item: Service) {
      await this.$router.push({
        name: 'service-edit',
        params: { id: item.id.toString() },
      });
    },

    async handleCreate() {
      await this.$router.push({
        name: 'service-create',
      });
    },
  },
});
